<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="videoDatas">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
          {{$t("elevatorNetApply.add")}}
        </el-button>
      </template>
      <table-column prop="coverPath" label="视频封面" :width="300" align="center">
        <template #default="scope">
          <img style="width: 80px;height: 80px" :src="scope.row.coverPath" alt="" @click.self="showBigImage($event)"/>
        </template>
      </table-column>
      <table-column prop="name" label="视频名称" :width="300" align="center"></table-column>
      <!--      <el-table-column prop="categoryId" label="种类"></el-table-column>-->
      <table-column prop="description" label="描述"></table-column>
      <table-column prop="status" label="状态" :width="100" align="center">
        <template #default="scope">
          <span v-if="scope.row.status===10">
            <el-tag type="info">未发布</el-tag>
          </span>
          <span v-else-if="scope.row.status===20">
            <el-tag type="success">已发布</el-tag>
          </span>
          <span v-else>
            <el-tag type="warning">作废</el-tag>
          </span>
        </template>
      </table-column>
      <table-column
        v-if="editAuth"
        :label="$t('common.operation')"
        :width="100"
        align="center"
        fixed="right"
        :tooltip="false"
        >
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{$t("common.edit")}}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{$t("common.delete")}}
          </el-button>
        </template>
      </table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
    <big-img
      :visible="photoVisible"
      :url="bigImgUrl"
      @closeClick="photoVisible = false"></big-img>
  </div>
</template>
<script>
  import EditDialog from "./VideoEdit.vue";
  import auth from "@/util/auth";
  import BigImg from "@/components/BigImg";

  export default {
    components: {EditDialog, BigImg},
    data() {
      return {
        editAuth: this.$auth(833),
        photoVisible: false,
        bigImgUrl: "",
        loading: false,
        search: {
          filter: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      showBigImage(e) {
        //点击图片函数，点击后，把photoVisible设置成true
        if (e !== "") {
          this.photoVisible = true;
          this.bigImgUrl = e.currentTarget.src;
        }
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + row.name + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.delete("videoDatas/" + row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
